var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var AssistCaseService = /** @class */ (function () {
    function AssistCaseService() {
    }
    /**
     * 协调案件查询
     */
    AssistCaseService.prototype.searchAssistCollectionCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.assistCaseController.searchAssistCollectionCase,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 外访分案预览
     */
    AssistCaseService.prototype.assistCaseDistributePre = function (data, loading) {
        return this.netService.send({
            server: businessService.assistCaseController.assistCaseDistributePre,
            data: data,
            loading: loading,
        });
    };
    /**
     * 外访分案确认
     */
    AssistCaseService.prototype.assistConfirmDistributeCase = function (data, loading) {
        return this.netService.send({
            server: businessService.assistCaseController.assistConfirmDistributeCase,
            data: data,
            loading: loading,
        });
    };
    /**
     * 结束协调
     */
    AssistCaseService.prototype.endAssistCaseCollecting = function (data, loading) {
        return this.netService.send({
            server: businessService.assistCaseController.endAssistCaseCollecting,
            append: [data],
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], AssistCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], AssistCaseService.prototype, "searchAssistCollectionCase", null);
    __decorate([
        Debounce()
    ], AssistCaseService.prototype, "assistCaseDistributePre", null);
    __decorate([
        Debounce()
    ], AssistCaseService.prototype, "assistConfirmDistributeCase", null);
    __decorate([
        Debounce()
    ], AssistCaseService.prototype, "endAssistCaseCollecting", null);
    return AssistCaseService;
}());
export { AssistCaseService };
