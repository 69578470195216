var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
// import { CaseInfoService } from "~/services/business-service/case-info.service";
// import { PersonalService } from "~/services/business-service/personal.service";
// import { AccTelPoolService } from "~/services/business-service/acc-tel-pool.service";
// import { OutsourcePoolService } from "~/services/business-service/outsource-pool.service";
import { Watch, Prop } from "vue-property-decorator";
import { CommonService } from "~/utils/common.service";
import clone from "clone";
var FollowRecord = /** @class */ (function (_super) {
    __extends(FollowRecord, _super);
    function FollowRecord() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            type: '',
            collectionFeedback: '',
            collectionFeedbacks: [],
            operatorTime: []
        };
        _this.dataSet = null;
        _this.caseTransfer = [];
        _this.feedOptions = [];
        _this.loading = {
            state: false
        };
        return _this;
    }
    FollowRecord.prototype.getRecord = function () {
        this.model = {
            type: '',
            collectionFeedback: '',
            collectionFeedbacks: [],
            operatorTime: [],
            caseNumber: this.caseNumber
        };
        this.caseTransfer = [];
        this.refreshData();
        if (this.out)
            return false;
        this.loading.state = true;
        // this.personalService.getCaseTurnRecord(this.caseNumber, this.loading).subscribe(data => {
        //   this.caseTransfer = data
        // });
    };
    /**
     * 流转记录title
     */
    FollowRecord.prototype.titleAndName = function (item) {
        return item.receiveUserRealName + "(" + item.receiveDeptName + ")";
    };
    /**
     * 调解反馈Change
     */
    FollowRecord.prototype.feedbackChange = function () {
        this.model.collectionFeedback = this.model.collectionFeedbacks[this.model.collectionFeedbacks.length - 1];
        if (!this.model.collectionFeedback) {
            this.model.collectionFeedback = '';
        }
    };
    /**
     * 下载录音
     */
    FollowRecord.prototype.loadCallFileHandle = function (url) {
        if (url === null) {
            this.$message.info('该案件没有录音文件！');
        }
        else {
            CommonService.downloadFile(url, '录音文件');
        }
    };
    /**
     * 播放录音
     */
    FollowRecord.prototype.checkCallFileHandle = function (url) {
        if (url === null) {
            this.$message.info('该案件没有录音文件！');
        }
        else {
            location.href = url;
        }
    };
    /**
     * 导出跟进记录
     */
    FollowRecord.prototype.exportFollowClick = function () {
        this.loading.state = true;
        // this.caseInfoService.exportFollowRecord(this.caseNumber, this.loading).subscribe(data => {
        //   this.$message.info('操作成功！')
        //   CommonService.downloadFile(data, '跟进记录')
        // }, ({ msg }) => {
        // });
    };
    /**
     * 重置
     */
    FollowRecord.prototype.handleResetForm = function () {
        this.model.dateRange = [];
        this.model.collectionFeedbacks = [];
    };
    FollowRecord.prototype.refreshData = function () {
        this.loading.state = true;
        this.dataSet = null;
        // if (this.out) {
        //   this.outsourcePoolService.getOutSourceCaseFollowRecord(this.model, this.pageService, { followTime: 'desc' }, this.loading).subscribe(data => {
        //     this.dataSet = data
        //   }, ({ msg }) => {
        //   });
        // } else {
        //   this.accTelPoolService.getFollowupRecord(this.model.caseNumber,this.model, this.pageService, { operatorTime: 'desc' }, this.loading).subscribe(data => {
        //     this.dataSet = data
        //   }, ({ msg }) => {
        //   });
        // }
    };
    FollowRecord.prototype.mounted = function () {
        var _this = this;
        // 调解反馈下拉框选项
        var valid = clone(this.$dict.getDictData('0019'));
        valid.forEach(function (v) {
            if (v.value === 88) {
                v.children = _this.$dict.getDictData('0020');
            }
            if (v.value === 89) {
                v.children = _this.$dict.getDictData('0021');
            }
        });
        this.feedOptions = valid;
        this.getRecord();
    };
    __decorate([
        Dependencies(PageService)
    ], FollowRecord.prototype, "pageService", void 0);
    __decorate([
        Prop()
    ], FollowRecord.prototype, "caseNumber", void 0);
    __decorate([
        Prop({
            type: Boolean,
            default: false
        }),
        Prop()
    ], FollowRecord.prototype, "out", void 0);
    __decorate([
        Watch('caseNumber')
    ], FollowRecord.prototype, "getRecord", null);
    FollowRecord = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], FollowRecord);
    return FollowRecord;
}(Vue));
export default FollowRecord;
